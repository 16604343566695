<!-- @format -->
<template>
  <b-card title="添加会员等级">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员等级">
              <validation-provider #default="{ errors }" name="会员等级" rules="required">
                <b-form-input v-model="editData.name" :state="errors.length > 0 ? false : null" placeholder="请输入等级名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="佣金百分比">
              <validation-provider #default="{ errors }" name="佣金百分比" rules="required">
                <b-form-input v-model="editData.rate" :state="errors.length > 0 ? false : null" placeholder="请输入佣金百分比" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="续费百分比">
              <validation-provider #default="{ errors }" name="续费百分比" rules="required">
                <b-form-input v-model="editData.renew_rate" :state="errors.length > 0 ? false : null" placeholder="请输入续费百分比" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="等级所需tp">
              <validation-provider #default="{ errors }" name="等级所需tp" rules="required">
                <b-form-input v-model="editData.level_tp" :state="errors.length > 0 ? false : null" placeholder="请输入等级所需tp" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { reactive, ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import LevelManagerModule from '@/views/GeneralManagement/VipManager/Level/LevelManagerModule'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
    flatPickr,
    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'level-module'
    const toast = useToast()
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, LevelManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const editData = reactive({
      name: '',
      rate: '',
      renew_rate: '',
      level_tp: '',
    })
    const { proxy } = getCurrentInstance()
    const createProdBrand = ref(null)
    const validationForm = () => {
      isRequest.value = true
      createProdBrand.value
        .validate()
        .then(response1 => {
          if (response1) {
            store.dispatch('level-module/createInvoices', { ...editData })
              .then(response => {
                if (response.code == 0) {
                  toast.success(response.msg || '添加成功')
                  setTimeout(() => {
                    isRequest.value = false
                    proxy.$router.back()
                  }, 1000)
                } else {
                  toast.error(response.msg)
                }
              })
          } else {
            isRequest.value = false
          }
        })
        .catch(error => {
          isRequest.value = false
          console.log(error)
        })
    }

    return {
      validationForm,
      editData,
      createProdBrand,
      isRequest,
      required,
      url,
    }
  },
}
</script>

<style scoped></style>
