/** @format */

import axios from '@axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    levelDetail: {},
  },
  getters: {},
  mutations: {
    setLevelDetail(state, payload) {
      state.levelDetail = payload
    },
  },
  actions: {
    // 查询全部数据
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/userLevelList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // 删除数据
    deleteInvoices(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/deleteUserLevel', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // 修改
    editInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/editUserLevel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/addUserLevel', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
